import React from 'react'
import "./footer.css"

function footer() {
  return (
    <div id="four">
        <div id="ficon">
            <div id="lfticon"></div>
            <h2>Let's get <span>to know</span></h2>
        </div>
        <div id="fline" class="line">
            <div class="hrline"></div>
        </div>
        <div id="getintouch">
            <h1>Know me more</h1>
        </div>
        <div id="buttons">
            <div id="fleftbtn">
                <a href="/">nirajrathod806@gmail.com</a>
            </div>
            <div id="frightbtn">
                <a href="/">+91 8847787650</a>
            </div>
        </div> 
    </div>
  )
}

export default footer