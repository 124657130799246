import React from 'react'
import "./nav.css" 
import "./sticky.js"
import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom';
const Nav= () => { 
    return (
      <header className='header'>   
            <div id="nav">
                <div id="leftnav">
                    <motion.a 
                      whileHover={{scale:1.5, fontWeight:600}} 
                    
                      href="/"><span>&copy;</span> Code by Himanshu Pawar</motion.a>
                </div>
                <div id="rightnav">
                    <div id="work">
                        <motion.a 
                            whileHover={{scale:1.2, fontWeight:600}}
                            href="/">Work</motion.a>
                        <div id="scir1" class="scir"></div>
                    </div>
                    <div id="about">
                        <motion.a whileHover={{scale:1.2, fontWeight:600}} href="/">About</motion.a>
                        <div id="scir2" class="scir"></div>
                    </div>
                    <div id="contact">
                        <motion.a whileHover={{scale:1.2, fontWeight:600}} href="/">Contact</motion.a>
                        <div id="scir3" class="scir"></div>
                    </div>
                </div>
            </div>
      </header>
    );
};

export default Nav;
