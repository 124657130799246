import React from 'react'
import "./home.css"    


const Home= () => {  
    return (
       <section className="home" id="home">
          <div id="mainelements">
                <div id="leftglobe">
                    <h1>Located <br/>in Italy</h1>
                    <div id="globec">
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle-hor"></div>
                        <div class="circle-hor-middle"></div>
                    </div>
                </div>
                <div id="freelance">
                    <i class="ri-arrow-right-down-line"></i>
                    <h1>Control System</h1>
                    <h1>Research Engineer</h1>
                </div>
                <div id="name">
                    <h1>Niraj J Rathod - Niraj J Rathod -</h1>
                </div>
            </div>
       </section>
    );
};

export default Home;
