import React from "react";
import './App.css';
import './removehi';
// import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  // createBrowserRouter,
  // RouterProvider, 
  Route, Routes
} from "react-router-dom";

import Home from './Components/Home/home'
import About from './Components/About/about'
import Contact from './Components/Contact/contact'
import Nav from './Components/Nav/nav'
import Footer from './Components/Footer/footer'


function App() {
  return (
    <BrowserRouter>
      <div id="hello"></div>
      <Nav/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="Home" element={<h2>Home</h2>}/>
        <Route path="About" element={<About/>}/>
        <Route path="Contact" element={<Contact/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
