import React from 'react'
import "./about.css"  
import { Link } from 'react-router-dom';

const about= () => { 
    return ( 
        <section> 
            <h1>About</h1>
            <Link to='/' className='btn'> Home </Link>
        </section>
    );
};

export default about;