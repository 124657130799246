import React from 'react'
import "./contact.css"  

const contact= () => { 
    return ( 
        <section> 
        </section>
    );
};

export default contact;